import { default as aboutzXt1Kid8qNMeta } from "/var/www/eclair-frontend/pages/about.vue?macro=true";
import { default as indexwHUkIYCU0BMeta } from "/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/index.vue?macro=true";
import { default as _91product_93lygt3z93TcMeta } from "/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/product/[product].vue?macro=true";
import { default as indexbhBsUwQozmMeta } from "/var/www/eclair-frontend/pages/catalog/[type]/[id]/index.vue?macro=true";
import { default as _91product_93tw5Kj00Ha2Meta } from "/var/www/eclair-frontend/pages/catalog/[type]/[id]/product/[product].vue?macro=true";
import { default as index4pEskJPQqwMeta } from "/var/www/eclair-frontend/pages/catalog/[type]/index.vue?macro=true";
import { default as contactsse4KPoliz5Meta } from "/var/www/eclair-frontend/pages/contacts.vue?macro=true";
import { default as cooperationuKsb1MF8QWMeta } from "/var/www/eclair-frontend/pages/cooperation.vue?macro=true";
import { default as faqV0EipWSf4EMeta } from "/var/www/eclair-frontend/pages/faq.vue?macro=true";
import { default as indexl4w3zJYpqSMeta } from "/var/www/eclair-frontend/pages/index.vue?macro=true";
import { default as _91id_93HrI70Tq0whMeta } from "/var/www/eclair-frontend/pages/news/[id].vue?macro=true";
import { default as indexX93BSwSgTTMeta } from "/var/www/eclair-frontend/pages/news/index.vue?macro=true";
import { default as vacancyebnEg0fBELMeta } from "/var/www/eclair-frontend/pages/vacancy.vue?macro=true";
import { default as component_45stubOEtG4YhNmVMeta } from "/var/www/eclair-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOEtG4YhNmV } from "/var/www/eclair-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___ru",
    path: "/about",
    component: () => import("/var/www/eclair-frontend/pages/about.vue")
  },
  {
    name: "about___uz",
    path: "/uz/about",
    component: () => import("/var/www/eclair-frontend/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/var/www/eclair-frontend/pages/about.vue")
  },
  {
    name: "catalog-type-id-group-group___ru",
    path: "/catalog/:type()/:id()/group/:group()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group___uz",
    path: "/uz/catalog/:type()/:id()/group/:group()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group___en",
    path: "/en/catalog/:type()/:id()/group/:group()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/index.vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___ru",
    path: "/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___uz",
    path: "/uz/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id-group-group-product-product___en",
    path: "/en/catalog/:type()/:id()/group/:group()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/group/[group]/product/[product].vue")
  },
  {
    name: "catalog-type-id___ru",
    path: "/catalog/:type()/:id()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id___uz",
    path: "/uz/catalog/:type()/:id()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id___en",
    path: "/en/catalog/:type()/:id()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/index.vue")
  },
  {
    name: "catalog-type-id-product-product___ru",
    path: "/catalog/:type()/:id()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type-id-product-product___uz",
    path: "/uz/catalog/:type()/:id()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type-id-product-product___en",
    path: "/en/catalog/:type()/:id()/product/:product()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/[id]/product/[product].vue")
  },
  {
    name: "catalog-type___ru",
    path: "/catalog/:type()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/index.vue")
  },
  {
    name: "catalog-type___uz",
    path: "/uz/catalog/:type()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/index.vue")
  },
  {
    name: "catalog-type___en",
    path: "/en/catalog/:type()",
    component: () => import("/var/www/eclair-frontend/pages/catalog/[type]/index.vue")
  },
  {
    name: "contacts___ru",
    path: "/contacts",
    component: () => import("/var/www/eclair-frontend/pages/contacts.vue")
  },
  {
    name: "contacts___uz",
    path: "/uz/contacts",
    component: () => import("/var/www/eclair-frontend/pages/contacts.vue")
  },
  {
    name: "contacts___en",
    path: "/en/contacts",
    component: () => import("/var/www/eclair-frontend/pages/contacts.vue")
  },
  {
    name: "cooperation___ru",
    path: "/cooperation",
    component: () => import("/var/www/eclair-frontend/pages/cooperation.vue")
  },
  {
    name: "cooperation___uz",
    path: "/uz/cooperation",
    component: () => import("/var/www/eclair-frontend/pages/cooperation.vue")
  },
  {
    name: "cooperation___en",
    path: "/en/cooperation",
    component: () => import("/var/www/eclair-frontend/pages/cooperation.vue")
  },
  {
    name: "faq___ru",
    path: "/faq",
    component: () => import("/var/www/eclair-frontend/pages/faq.vue")
  },
  {
    name: "faq___uz",
    path: "/uz/faq",
    component: () => import("/var/www/eclair-frontend/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/var/www/eclair-frontend/pages/faq.vue")
  },
  {
    name: "index___ru",
    path: "/",
    component: () => import("/var/www/eclair-frontend/pages/index.vue")
  },
  {
    name: "index___uz",
    path: "/uz",
    component: () => import("/var/www/eclair-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/eclair-frontend/pages/index.vue")
  },
  {
    name: "news-id___ru",
    path: "/news/:id()",
    component: () => import("/var/www/eclair-frontend/pages/news/[id].vue")
  },
  {
    name: "news-id___uz",
    path: "/uz/news/:id()",
    component: () => import("/var/www/eclair-frontend/pages/news/[id].vue")
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    component: () => import("/var/www/eclair-frontend/pages/news/[id].vue")
  },
  {
    name: "news___ru",
    path: "/news",
    component: () => import("/var/www/eclair-frontend/pages/news/index.vue")
  },
  {
    name: "news___uz",
    path: "/uz/news",
    component: () => import("/var/www/eclair-frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/var/www/eclair-frontend/pages/news/index.vue")
  },
  {
    name: "vacancy___ru",
    path: "/vacancy",
    component: () => import("/var/www/eclair-frontend/pages/vacancy.vue")
  },
  {
    name: "vacancy___uz",
    path: "/uz/vacancy",
    component: () => import("/var/www/eclair-frontend/pages/vacancy.vue")
  },
  {
    name: "vacancy___en",
    path: "/en/vacancy",
    component: () => import("/var/www/eclair-frontend/pages/vacancy.vue")
  },
  {
    name: component_45stubOEtG4YhNmVMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubOEtG4YhNmV
  },
  {
    name: component_45stubOEtG4YhNmVMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubOEtG4YhNmV
  },
  {
    name: component_45stubOEtG4YhNmVMeta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stubOEtG4YhNmV
  },
  {
    name: component_45stubOEtG4YhNmVMeta?.name,
    path: "/uz-sitemap.xml",
    component: component_45stubOEtG4YhNmV
  },
  {
    name: component_45stubOEtG4YhNmVMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubOEtG4YhNmV
  }
]